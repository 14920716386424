import React, { useEffect } from "react"
import { Link } from "gatsby"

import {
  // CalendarIcon,
  ChevronRightIcon,
  // UsersIcon,
  UserGroupIcon,
  // CollectionIcon,
  ClipboardListIcon,
  // SparklesIcon,
  TagIcon,
} from '@heroicons/react/outline'

import Image from "../common/image"
import CommunityFeatureTags from "../feature/communityfeaturetags"
import CommunityStarRating from "./starrating"

import { unslugify, readableNumber } from '../../utils/text'

import "../../styles/scroller.css"

const ScrollingCommunities = ({communities}) => {

  useEffect(() => {
    [...document.querySelectorAll('.scroller .column')].map(column => {
      column.style.setProperty('--animation', 'slide');
      column.style.setProperty('height', '200%');
      column.innerHTML = column.innerHTML + column.innerHTML;
    });

  }, []); // instantiate the scroll


   // split it into 2 columns for now
  const columns = [
    {'col': '1', 'items': communities.filter((c, index) => index % 3 === 0 && c.logo)},
    {'col': '2', 'items': communities.filter((c, index) => index % 3 === 1 && c.logo)},
    {'col': '3', 'items': communities.filter((c, index) => index % 3 === 2 && c.logo)}
  ]

  return (
    <div className="scroller space-x-3 p-1 pr-2">
      {columns.map((column) => (
        <div className="column space-y-3" key={column.col}>
          {column.items.map((c, index) => (
            <div key={c.slug}>
              <Link to={`/communities/${c.slug}/`} rel="nofollow" className="card block bg-white shadow-md rounded-md p-3">
                
                <div className="pl-0 flex-shrink-0 flex-grow-0">
                  {c.logo ? (
                    <img className="h-12 object-contain object-left rounded-md" src={c.logo} alt={`${c.name} logo`} height="42" width="100%" loading="lazy" />
                  ) : (
                    <Image className="h-12 object-contain object-left opacity-50" fileName="icon.png" alt="Community Placeholder" height="42" width="100%" loading="lazy" />   
                  )}
                </div>
                <div className="mt-2 overflow-hidden flex text-sm items-end">
                  <p className="font-medium text-gray-700 truncate">{c.name}</p>
                  {c.platform && c.platform !== 'independent' ? (
                    <p className="ml-1 flex-shrink-0 font-normal text-xs text-gray-400"> on {c.platform}</p>
                  ) : ''}
                </div>
                
                <div className="mt-4 block sm:inline text-xs sm:w-40 text-gray-500 flex-shrink-0 sm:ml-auto">
                  {c.members ? (
                    <div className="flex items-center">
                      <UserGroupIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" aria-hidden="true" />
                      <p>
                        {readableNumber(c.members)} members
                      </p>
                    </div>
                  ) : ''}
                  {c.topics && c.topics.length ? (
                    <div className="flex items-center align-start">
                      <ClipboardListIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" aria-hidden="true" />
                      <p>
                        {c.topics.split(',').map(t => unslugify(t)).join(', ')}
                      </p>
                    </div>
                  ) : ''}

                  {c.reviewCount > 0 ? (
                    <div className="text-sm mt-2">
                      <CommunityStarRating community={c} />
                    </div>
                  ) : ''}
                  
                  <div className="mt-2 flex items-center align-start text-xs">
                    {/*<TagIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" aria-hidden="true" />*/}
                    <CommunityFeatureTags community={c} />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default ScrollingCommunities;
